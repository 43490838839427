<template>
	<div class="container">
		<van-pull-refresh v-model="refreshing" @refresh="onRefresh" :style="{ 'min-height': 'calc(100vh)' }">
			<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
				<div class="msg-box">
					<template v-for="(item, index) in list">
						<div class="row item" @click="navMsg(index)">
							<div class="avatar" :class="{ dot: item.is_read == 0 }"><van-image round :src="item.avatar" fit="cover" /></div>
							<div class="text-view-box">
								<div class="row cb msg-main">
									<div class="col text-view">
										<div class="row cb hd">
											<div class="title text-ellipsis">{{ item.nickname }}</div>
											<div class="time">{{ item.create_time_text }}</div>
										</div>
										<div class="content text-ellipsis">赞了您的回答@「{{ item.title }}」</div>
									</div>
								</div>
							</div>
						</div>
					</template>
				</div>
			</van-list>
		</van-pull-refresh>
	</div>
</template>

<script>
export default {
	data() {
		return {
			page: 0,
			pageSize: 20,
			list: [],
			loading: false,
			finished: false,
			refreshing: false
		};
	},
	mounted() {},
	methods: {
		navMsg(index) {
			this.$set(this.list[index], 'is_read', 1);
			let item = this.list[index];
			this.$service.post('user_info/readMess', { mess_id: item.mess_id });
			this.$router.push({
				path: '/ask/detail',
				query: { id: item.question_id }
			});
		},
		onLoad() {
			if (this.refreshing) {
				this.list = [];
				this.refreshing = false;
			} else {
				console.log('onLoad');
				this.loading = true;
				this.page++;
				this.$service
					.post('user_info/agreeList', {
						page: this.page,
						pageSize: this.pageSize
					})
					.then(res => {
						let data = res.data.data;
						this.list = this.list.concat(data);
						this.loading = false;
						if (data.length < this.pageSize) {
							this.finished = true;
						}
					});
			}
		},
		onRefresh() {
			console.log('onRefresh');
			this.page = 0;
			this.finished = false;
			this.refreshing = true;
			this.onLoad();
		}
	}
};
</script>

<style lang="less" scoped>
@import './msg.less';
</style>
